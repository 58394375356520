@media screen and (min-width: 1650px) {
    #svg_style{width: 80%!important;height: 80%!important;}
}
.status_userbox1{
    height: 450px;
    cursor: pointer;
    border-radius: 5px;margin-right: 1%;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);float: left;}
.footTitle{text-align: left;height: 44px;background: #eee;line-height: 44px;}
.right_footTitle{float: left;margin-left: 14%}
.left_footTitle{float: right;margin-right: 14%}
.footTitle_box{height: 20%;} 