.bli_Select{width: 130px;}
.search_time_Box{height: 32px;width: 100px!important;}
.search_time_label{margin-left: 10px;}
.billing_search_icon{margin-left: 10px!important;cursor: pointer;}
.bli_Select_half_month{width: 80px;}
.search_patient_container {
    margin-bottom: 10px;
    text-align: left;
    white-space: nowrap;
}
.add_import_box{margin-bottom: 10px;margin-top: 10px;}
#return-to-device-list {
    position: fixed;
    right: 0;
    z-index: 999;
}
.spanonclick{cursor: pointer;}
