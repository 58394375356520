.Account-root-1 {
  margin: 0 auto;
}
.checkTotal {
  display: block;
  width: 400px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 30px;
}
.inputTotal {
  display: block;
  width: 250px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 20px;
}
#finishIcon {
  font-size: 100px;
  color: #4a90e2;
}
.inputContent {
  width: 250px;
}
.selectStyle {
  width: 250px;
  height: 40px;
  -webkit-appearance: menulist-button;
}
.newSite_box {
  width: 100%;
  margin: 0 auto;
}
.success_box{
  margin: 0 auto;
  text-align: center;
}
.Account-root-1 {
  margin: 0 auto;
}
.checkTotal {
  display: block;
  width: 400px;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 30px;
}
.checkTitle {
  font-size: 20px;
  font-weight: 600;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.inputLable {
  margin: 0 !important;
  padding: 0 !important;
}
#siteIcon {
  font-size: 130px;
  color: #4a90e2;
  margin-bottom: 60px;
  margin-top: 20px;
}
.siteTitle {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.finishtext {
  font-weight: 500;
  margin-bottom: 10px;
}
.createButton {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
  margin-top: 30px!important;
}
.doctorAccount {
  border: 1px solid #ababab;
  width: 80%;
  margin: 0 auto;
  box-shadow: 1px 1px 1px #ababab;
}
.createAccount {
  background-color: #4a90e2 !important;
  color: #ffffff !important;
  margin-bottom: 10px;
  margin-left: 200px;
}
.customHeight {
  height: 10px;
}
#failedIcon {
  font-size: 80px;
  color: #4a4a4a;
  margin-bottom: 30px;
  margin-top: 80px;
}
.addUnderline{text-decoration: underline;}
.checkbox_label{margin-right: 20px;}
.checkbox_box{vertical-align:middle!important; margin-top:0!important;}
.site-add-icon{cursor: pointer;margin: 0!important;padding: 0!important;}
.add_site_button{background-color: #f5f6f7;cursor: pointer;border: 1px solid #ccd0d5;
  border-radius: 2px;justify-content: center;font-size: 12px;height: 18px;
  line-height: 18px;text-align: center;vertical-align: middle;padding: 0 8px;color: #4b4f56;
}
.staff_log_tip{font-size: 16px;}
