.photoBox{height: 330px;}
.swiperBoxNew{width: 50%!important;}
.swiperP{border-left: 1px solid #ababab;font-size: 14px;margin-bottom: 0px;height: 30px;line-height: 30px;}
.swiperimg{width: 100%;text-align: center;height: 300px;float: left;object-fit: cover;}
.totalbox{height: 300px;}
.bigImage{border: 1px solid #ababab;box-shadow: 1px 1px #ababab;
    width: 700px;height: 500px;z-index: 9999;top: 50%;margin-top: -225px;
    position: absolute;left: 50%;margin-left: -350px;background-color: #fff;}
.swiper-container{z-index: 0!important;}
.closeIcon{height: 36px;background-color: #f0f0f0;text-align: right;}
.contentBox{height: 400px;width: 80%;margin-top: 34px;margin-bottom: 30px;margin-left: 10%;
    margin-right: 10%;}
.swiperBigerimg{width: auto;float: left;max-height: 400px;}
.swiperDate{width: 40%;float: right;}
.slick-list{margin-left: 2%!important;margin-right: 2%!important;}
.slick-prev {left: 0!important;z-index: 99999;}
.slick-next {right: 0!important;z-index: 99999;}
.slick-prev:before,.slick-next:before {color: #000!important;}
.close{cursor: pointer}
.clickIcon{line-height: 36px;}
[tabindex="-1"]:focus {
    outline: none!important;
}
#zmage{z-index: 999999999!important;}