.device-detail-container{}
.checkboxlabel{margin-right: 20px;}
.checkboxlabel input{vertical-align: middle!important;}
.kongge{margin-left: 5px;}
.ui.segment{overflow-y:scroll}

#user-info-container3{width: 100%;margin-top: 15px;border-radius: 5px;
  box-shadow: 1px 1px 4px 0 1px 1px 4px 0 rgba(177, 11, 11, 0.15);
  padding: 10px 0 0;
}
.clear_both{clear: both;}

.imgboxtotal{height: 300px;width: 100%;}

.box1header2{margin-left: 34px;}
.userbox4{clear: both;}
.react-calendar{width: 100%!important;line-height: 1.5em!important;}
.ico1{width: 10px;height: 10px;border:2px solid #dab4fd!important;border-radius: 20%;top:50%;margin-top:-5px;position: absolute}
.ico1text{margin-left: 20px;;line-height: 25px;}
.ico1box{height: 25px;position: relative;width: 80%;margin: 0 auto;margin-top: 10px;text-align: left;}
.ico2{width: 10px;height: 10px;border-radius: 50%;background-color: #e2d956;top:50%;margin-top:-5px;position: absolute}
.ico2text{margin-left: 20px;;line-height: 25px;}
.ico3{width: 10px;height: 10px;border-radius: 50%;background-color: rgb(252, 13, 27,1);top:50%;margin-top:-5px;position: absolute}
.ico3text{margin-left: 20px;;line-height: 25px;}
/* .react-calendar__tile--now{background-color:rgba(218, 180, 253,0.6)!important;} */
.react-daterange-picker__calendar .react-calendar__tile--rangeStart{background-color:rgba(0,0,0,.1);border-radius: 20%;border:3px solid #a989c5!important;}
.react-daterange-picker__calendar .react-calendar__tile--rangeEnd{background-color:rgba(0,0,0,.1);border-radius: 20%;border:3px solid #a989c5!important;
}
.react-calendar__tile--active{background-color:rgba(0,0,0,.1)!important;border: 1px solid #a989c5!important;color:black!important;border-radius: 20%;}
/* .react-calendar__tile--hasActive{color:white;background-color:#a989c5!important;} */
.react-calendar__tile--hover {background-color: #a989c5;}
.normalmetabase2{background-color: rgba(15, 127, 18,1)!important;color:white!important;}
.normalmetabase{background-color: rgba(15, 127, 18,0.6)!important;color:white!important;}
.yellowmetabase2{background-color: rgb(255, 253, 56,1)!important;color:rgb(94, 29, 29)!important;}
.yellowmetabase{background-color: rgb(255, 253, 56,0.6)!important;color:black!important;}
.redmetabase2{background-color: rgb(252, 13, 27,1)!important;color:white!important;}
.redmetabase{background-color: rgb(252, 13, 27,0.6)!important;color:white!important;}
.react-daterange-picker__inputGroup__month{width: 28px!important;}
.react-daterange-picker__inputGroup__year{width: 48px!important;}
.react-daterange-picker__inputGroup__day{width: 28px!important;}
.urlstyle{float: right;}
.urlbox{width: 100%;text-align: center;}
.complianceBlank{width: 100%;height: 310px;}
.timeMore{background-color: rgb(78, 25, 116)!important;color:white!important;}
