.patient_info_box{height: 32%!important;}
.sym_box{height: 150px;margin-top: 4px;}
.notes_box{height: 330px;overflow:auto}
.note_add_button{text-align: right}
.note_title_box{background-color: #210738;height: 30px;line-height: 30px;color: #fff; font-weight: 800;}
.note_title{float: left;text-align: right;width: 55%;}
.note_add_button{float: right;}
.note_button{cursor: pointer;border-radius: .28571429rem;height: 30px!important;padding: 0 1.5em 0 1.5em!important;margin: 0px!important;background-color: rgba(99, 50, 141, 0.9);color: #fff; font-weight: 800;}
.clear_both{clear: both;}
.info_sym{height: 40%;}
.patient_title{float: left;text-align: right;width: 60%;}