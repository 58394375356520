.device-detail-container #return-to-device-list {
  margin: 1rem 0;
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: 100;
  background: #fff;
}

.ui.segment {
  height: auto !important;
}
.seg-title {
  text-align: left;
  margin-bottom: 30px;
}
.floattext {
  text-align: left;
  width: 100%;
}
.lefttext {
  width: 49%;
  float: left;
  margin: 0;
}
.righttext {
  width: 49%;
  float: right;
  margin: 0;
}
.Statistics-container {
  padding: 20px;
}
#return-to-device-list {
  position: fixed;
  right: 0;
  z-index: 999;
}
/* .label_sirenId_box{font-weight: 700;color: rgba(0,0,0,.6);font-size: 16px;background-color: #e8e8e8;width: 140px;height: 35px;line-height: 35px;border-radius: 5px;margin-bottom: 5px;} */
.label_sirenId_box{margin-bottom: 25px;text-align: left;}
.label_sirenId{font-size: 16px!important;padding: 0px;margin: 0px;left: 0px!important;}
.noResock{background-color: #e8e8e8;}
