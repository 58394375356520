.tab_container{text-align: left;border-bottom: 1px solid rgba(34,36,38,.15);margin-bottom: 30px;padding: 0px 0px 12px 0px;}
.tab_nav-active{margin: 0 32px 0 0;cursor: pointer;font-size: 14px;padding: 12px 16px;
    line-height: 1.5;font-weight: 500;color: #1890ff;border-bottom: 2px solid #1890ff;}
.tab_nav{margin: 0 32px 0 0;cursor: pointer;font-size: 14px;padding: 12px 16px;
    line-height: 1.5;font-weight: 500;color: rgba(0,0,0,.65);}
.change99457BilledTip{margin-bottom: 0px;}
.fixedButtonHeight{height: 36px;}
.Multiple_Box{font-size: 14px;color: rgba(0, 0, 0, 0.65);margin-top: 3px;}
.summary_des_container{border: 2px solid rgba(0,0,0,0);border-radius: 5px;margin-bottom: 20px;margin-top: 30px;align-items:center; display: -webkit-flex;height: 80px;position: relative;}
.report_des_container_dark{background-color: #4F1974;border: 2px solid rgba(0,0,0,0.02);border-radius: 5px;margin-bottom: 20px;align-items:center; display: -webkit-flex;height: 80px;position: relative;width:300px;}
.report_des_container_light{background-color: #B892D2;border: 2px solid rgba(0,0,0,0.02);border-radius: 5px;margin-bottom: 20px;align-items:center; display: -webkit-flex;height: 80px;position: relative;width:300px;}
.summary_des_box{background-color: #f3f3f5;display: inline-block;margin-left: 10px;margin-right: 10px;height: 80px;width:200px;border: 2px solid rgba(0,0,0,0.02);border-radius: 5px;}
.summary_des_number{font-weight: 700;color: #000;font-size: 20px;margin-bottom: 0px;text-align:left;}
.summary_des_title{font-weight: 500;color: #000;font-size: 12px;margin-bottom: 0px;}
.summary_icon{color: rgb(45, 1, 114);font-size: 30px;position: absolute;top: 6px;right: 6px;}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month{background: #B892D2;border-radius: 50%;width: 40px;height: 40px;line-height: 40px;}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover{background: #B892D2;border-radius: 50%;width: 40px;height: 40px;line-height: 40px;}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year{background: #B892D2;border-radius: 50%;width: 50px;height: 50px;line-height: 50px;}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover{background: #B892D2;border-radius: 50%;width: 50px;height: 50px;line-height: 50px;}
.popContent{color:#4A4A4A}
.popTitle{margin-bottom: 0px;color: #000;font-weight: bold;}
.popBox{text-align: left;background-color: #f3f3f5;padding: 30px;}
.popContainer{margin-bottom: 30px;}
.popHeader{margin-top: 30px;margin-bottom: 15px;margin-left: 30px;}
.tab_title{color: #000;font-size: 18px;padding: 0px 16px;}
.tab_title_box{margin-top: 14px!important;margin-bottom: 20px;}
.search_label_unbilled{line-height: 36px;}
.search_label{margin-right: 10px;}
.selectContainer_unbilled{margin-top: 2px;}
.report_des_container{align-items:center; display: -webkit-flex;height: 80px;position: relative;}
