.device-list-container{
}
.device-list-container .view-more-eye {
  cursor: pointer; }

.device-list-container .icon-comments-edit {
  margin-left: 5px; }

.device-list-container .view-action-history {
  cursor: pointer;
  margin-left: 4px; }

.device-list-container h2 {
  display: inline-block; }

.ui.table.result-table {
  margin-top: 0; }

.sockstatus{cursor: pointer;}
.totalbox{position: relative;}
.updownbox{position: absolute;right: 0;top: 40%;cursor: pointer;}
.upbox{position: absolute}
.Nav__nav___nBWON{padding: 4px!important;}
.rt-resizable-header-content{white-space: normal;}/* 强制换行 */
.rt-resizable-header{align-items:center;display: -webkit-flex;justify-content:center; }
.ReactTable .rt-thead.-headerGroups{display: none;}
.ReactTable .rt-thead .rt-resizable-header{overflow: hidden;}
.spanonclick{color:#6699CC;font-weight: 600;}
.spanonclick:hover{text-decoration: underline;}
/* .ui.selection.dropdown{min-width: 2px!important;max-width: 90px!important;} */
/* .Dropdown-menu{position: relative;}
.Dropdown-control{height: 36px;} */
.edit{margin-left: 5px!important;}
.ifchange2{visibility: hidden!important;}
body{color: rgba(0,0,0,.65);}
button, input, optgroup, select, textarea {
  margin: 0;
  color: inherit;
  font-family: inherit;
  line-height: inherit;
}
.patient_condition{text-align: left;margin-left: 30%;}
.patient_condition_bk{text-align: left;margin-left: 70%;}
.diagnosis_codes{text-align: left;margin-left: 45%; margin-top: 10%;}
.diagnosis_codes_new{text-align: left;margin-left: 30%; margin-top: 20px;}
.diagnosis_codes_bk{text-align: left;margin-left: 70%; margin-top: 10%;}
.add_diagnosis_code{cursor: pointer;margin: 0!important;padding: 0!important;}
.patient_note_label{margin-right: 10px;font-weight: 600;color: rgba(0,0,0,0.87);}
.patient_note_label_new{margin-right: 10px;font-weight: 600;color: rgba(0, 0, 0, 0.87);text-align: left;margin-top: 20px;}
