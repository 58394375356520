.info{
    float: left;height: 480px;
}
.title{background-color: #210738;
    height: 30px;line-height: 30px;
    color: #fff; font-weight: 800;
}
.patientContent{width: 100%;}
.patient_info_title{font-size: 12px;}
.patient_info_details{font-size: 12px;} 
.patient_info_details_email{word-break: break-all;}
.site-collapse-custom-panel {
    border-radius: 0 !important;
}
.site-collapse-custom-panel > .ant-collapse-header {
    padding: 0 !important;
}

.site-collapse-custom-panel-overflow-visible .ant-collapse-content-active {
    overflow: visible;
}