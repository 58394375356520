.deleteButton{background-color: #db2828!important;color: #fff!important;}
.okButton{width: 104px!important;}
.removeButton{width: 104px!important;}
.closebutton {
    padding: 0;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin: 0 auto;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff !important;
    border: 1px solid #000 !important;
    color: #000 !important;
    cursor: pointer;
}
.closeicon {
   margin: 0 !important;
   padding: 0 !important;
}
.edit_Icon{color:#69c;}
